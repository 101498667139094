module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_ngfs5lbknxrehi4ppvfwma5w6y/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#000000","display":"minimal-ui","icon":"content/icon.jpg","icons":[{"sizes":"512x512","src":"/favicon-512x512.png","type":"image/png"}],"legacy":false,"name":"Huey","short_name":"huey","start_url":"/","theme_color":"#000000","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7c79fe67c5f1f30d79cfd884c9df30e7"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_esli_enc3bsjghui3uy3hvx5pr3lwbe/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":1080,"withAvif":true,"withWebp":true,"wrapperStyle":"max-height: 750px; overflow: hidden;","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-_4zjngirfxsec3g6ozwa5ogmlcu/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18._27tihtdts3hvxghqvpbk5ivklu/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__lwluc2zr64peptjh3labae5zmq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
